<template>
  <div>
    <el-dialog
      title="记账商品列表"
      :visible.sync="addProduct"
      :before-close="productClose"
    >
      <div class="product" v-if="!productShow">
        <div class="product-header">
          <el-button
            type="success"
            icon="el-icon-circle-check"
            size="mini"
            @click="handleAdd"
            >添加商品</el-button
          >
          <el-button
            type="success"
            icon="el-icon-circle-check"
            size="mini"
            @click="batchDelete"
            >批量删除</el-button
          >
        </div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="编号" prop="number" sortable>
          </el-table-column>
          <el-table-column label="名称" prop="product_name" sortable>
          </el-table-column>
          <el-table-column label="单位" prop="unit" sortable> </el-table-column>
          <el-table-column label="市场价" prop="market_price" sortable>
          </el-table-column>
          <el-table-column label="销售价" prop="sales_price" sortable>
          </el-table-column>
        </el-table>
      </div>
      <AddProduct
        :productShow="productShow"
        @addConfirm="addConfirm"
        :isPop="true"
        v-if="productShow"
      />
    </el-dialog>
  </div>
</template>
<script>
import AddProduct from "../../../../addProduct/addProduct.vue";
export default {
  props: ["addProduct", "tableDatas"],
  components: { AddProduct },
  data() {
    return {
      tableData: [],
      products: [],
      productShow: false,
    };
  },
  mounted() {
    this.tableData = this.$props.tableDatas || [];
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.products = val;
      console.log(val);
    },
    productClose() {
      this.$emit("productClose", this.tableData);
      this.productShow = false;
    },
    handleAdd() {
      this.productShow = true;
    },
    addConfirm(data1) {
      data1.forEach((item) => {
        let isPush = true;
        for (let i = 0; i < this.tableData.length; i++) {
          if (item.id == this.tableData[i].id) {
            isPush = false;
            break;
          }
          isPush = true;
        }
        if (isPush) {
          this.tableData.push(item);
        }
      });
      //   this.tableData = arr;
      this.productShow = false;
    },
    batchDelete() {
      this.products.forEach((item) => {
        this.tableData.forEach((item1, index) => {
          if (item1.id == item.id) {
            this.tableData.splice(index, 1);
          }
        });
      });
    },
  },
};
</script>
<style scoped></style>
