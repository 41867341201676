<template>
  <div>
    <div
      style="
        width: 100%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      "
    >
      <el-button type="success" style="margin-right: 20px" @click="dialogVisible = true">添加地址</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="consignee" label="收货人"> </el-table-column>
      <el-table-column prop="deliveryAddress" label="收货地址">
      </el-table-column>
      <el-table-column prop="phone" label="联系电话"> </el-table-column>
      <el-table-column prop="floor" label="楼层"> </el-table-column>
      <el-table-column prop="default" label="默认"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <img
            style="width: 16px; height: 16px"
            :src="require('@/assets/iconImg/logo.png')"
            @click="handleEdit(scope.$index, scope.row)"
          />
          <span style="margin: 0 10px"></span>
          <img
            style="width: 16px; height: 16px"
            :src="require('@/assets/iconImg/logo.png')"
            @click="handleDelete(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="收货地址"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="收货人：" prop="consignee">
            <el-input
              v-model="ruleForm.consignee"
              placeholder="客户"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="所在地区：" required>
            <span>{{ ruleForm.region }}</span>
            <el-button type="primary" style="margin-left: 5px;">选择</el-button>
          </el-form-item>
          <el-form-item label="详细地址：" prop="address">
            <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="10">
              <el-form-item label="楼层：">
                <el-select v-model="ruleForm.floor">
                  <el-option
                    v-for="item in floores"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="是否默认：">
                <el-checkbox v-model="ruleForm.checked"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="配送站点：">
            <el-select
              v-model="ruleForm.station"
              clearable
              placeholder="- 选择配送站点 -"
            >
              <el-option
                v-for="item in stationes"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,

      ruleForm: {
        consignee: "",
        phone: "",
        region: "广东广州黄埔",
        address: "",
        floor: "",
        checked: false,
        station: "",
      },
      rules: {
        consignee: [{ required: true, message: "必填字段", trigger: "blur" }],
        phone: [{ required: true, message: "必填字段", trigger: "blur" }],
        address: [{ required: true, message: "必填字段", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  computed: {},
  methods: {
    handleEdit() {},
    handleDelete() {},

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped></style>
