<template>
  <div>
    <el-dialog
      title="会员选择"
      :visible.sync="addUser"
      :before-close="beforeClose"
    >
      <div class="select" v-if="!addShow">
        <div class="select-search">
          <el-input
            class="select-search-inp"
            placeholder="请输入卡号/手机号/地址"
            v-model="searchInp"
          />
          <el-button
            type="success"
            icon="el-icon-search"
            style="margin-left: 10px"
            size="mini"
            @click="handleSearch"
            >查询</el-button
          >
          <el-button
            type="success"
            icon="el-icon-search"
            style="margin-left: 10px"
            size="mini"
            @click="handleConfirm"
            >确定</el-button
          >
          <el-button
            type="success"
            icon="el-icon-search"
            style="margin-left: 10px"
            size="mini"
            @click="addShow = true"
            >新增会员</el-button
          >
        </div>
        <el-table
          :data="useTab"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="姓名" width="120" prop="member_name">
          </el-table-column>
          <el-table-column label="手机" width="120" prop="bind_phone">
          </el-table-column>
          <el-table-column label="地址" prop="address"> </el-table-column>
        </el-table>

        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;{{
              useTab.length == 0 ? 0 : (page - 1) * length + 1
            }}&nbsp;至&nbsp;{{
              (page - 1) * length + useTab.length
            }}&nbsp;项结果，共&nbsp;{{ useTabNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="1"
            ></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
      <AddGroup v-else @close="onClose" />
    </el-dialog>
  </div>
</template>
<script>
import AddGroup from "./memberAddGroup.vue";
export default {
  props: ["addUser"],
  components: { AddGroup },
  data() {
    return {
      searchInp: "",
      useTab: [],
      multipleSelection: "",
      addShow: false,
      page: 1,
      length: 10,
      useTabNum: "",
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getUserList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSearch() {
      this.$http
        .post("/Client/member/searchSelect", {
          search: this.searchInp,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.useTab = res.data.data;
          this.useTabNum = res.data.count;
        });
    },
    beforeClose() {
      this.$emit("beforeClose");
    },
    handleConfirm() {
      this.$emit("handleConfirm", {
        name: this.multipleSelection[this.multipleSelection.length - 1]
          .member_name,
        id: this.multipleSelection[this.multipleSelection.length - 1].member_id,
      });
    },
    getUserList() {
      this.$http
        .post("/client/member/lst", {
          currentPage: this.page,
          currentLength: this.length,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.useTab = res.data.data;
          this.useTabNum = res.data.count;
        });
    },
    onClose() {
      this.addShow = false;
      this.getUserList();
    },
  },
};
</script>
<style lang="less" scoped>
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
.select {
  &-search {
    display: flex;
    margin-bottom: 20px;
    &-inp {
      width: 30%;
    }
  }
}
</style>
