<template>
    <div>
        <el-dialog :title="title + '明细'" :visible.sync="detailShow" :before-close="detailClose">
            <div class="header">
                <div class="header-text">使用时间：</div>
                <el-date-picker v-model="value1" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
                <span style="margin: 0 10px">-</span>
                <el-date-picker v-model="value2" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
                <el-button type="success" icon="el-icon-search" size="mini" @click="searchClick">查询</el-button>
                <el-button type="success" icon="el-icon-delete" size="mini" @click="clearClick">清除</el-button>
            </div>
            <el-table :data="tableData" style="width: 100%" border>
                <el-table-column prop="create_time" label="日期"></el-table-column>
                <el-table-column prop="reason" label="备注"></el-table-column>
                <el-table-column prop="income" label="收入"></el-table-column>
                <el-table-column prop="expenditure" label="支出"></el-table-column>
                <el-table-column prop="current_inventory" label="本期库存"></el-table-column>
            </el-table>
            <div class="tableBottom">
                <span style="">显示第&nbsp;{{
                    tableData.length == 0 ? 0 : (page - 1) * length + 1
                }}&nbsp;至&nbsp;{{
    (page - 1) * length + tableData.length
}}&nbsp;项结果，共&nbsp;{{ count }}&nbsp;项</span>

                <div style="display: flex; justify-content: flex-end; align-items: center">
                    <el-button @click="homePage">首页</el-button>
                    <el-button @click="upPage">上页</el-button>
                    <el-pagination background layout=" pager,slot" :total="count" :page-size="length" :current-page="page"
                        @current-change="currentChange"></el-pagination>
                    <el-button @click="nextPage">下页</el-button>
                    <el-button @click="endPage">末页</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["detailShow", "title", "id"],
    data() {
        return {
            value1: "",
            value2: "",
            tableData: [],
            count: "",
            page: 1,
            length: 5,
            search: false,
        };
    },
    mounted() {
        this.getTabList();
    },
    computed: {},
    methods: {
        detailClose() {
            this.$emit("detailClose");
        },
        getTabList() {

            this.$http
                .post("/client/balance_opretion/search_2", {
                    firstTime: this.value1,
                    lastTime: this.value2,
                    member_id: this.$props.id,
                    currentPage: this.page,
                    currentLength: this.length,
                })
                .then((res) => {
                    this.tableData = res.data.data;
                    this.count = res.data.count;
                });
        },
        homePage() {
            this.page = 1;
        },
        upPage() {
            if (this.page == 1) return;
            this.page -= 1;
        },
        nextPage() {
            if (this.page >= Math.ceil(this.count / this.length)) return;
            this.page += 1;
        },
        endPage() {
            this.page = Math.ceil(this.count / this.length);
        },
        currentChange(page) {
            this.page = page;
        },
        searchClick() {
            this.search = true;
            this.page = 1;
            this.getTabList();
        },
        // searchTabList() {
        //     this.$http
        //         .post("/client/balance_opretion/search", {
        //             member_id: this.$props.id,
        //             firstTime: this.value1,
        //             lastTime: this.value2,
        //             currentPage: this.page,
        //             currentLength: this.length,
        //         })
        //         .then((res) => {
        //             this.tableData = res.data.data;
        //             this.count = res.data.count;
        //         });
        // },
        clearClick() {
            this.value1 = "";
            this.value2 = "";
            this.search = false;
            this.page = 1;
        },
    },
    watch: {
        page: {
            handler() {
                if (!this.search) {
                    this.getTabList();
                } else {
                    this.getTabList();
                }
            },
        },
    },
};
</script>
<style lang="less" scoped>
.header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &-text {
        white-space: nowrap;
    }

    .el-date-editor.el-input {
        width: 180px;
    }
}

.tableBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666;
    font-size: 14px;
}
</style>
  