<template>
  <div>

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted(){

  },
  computed:{

  },
  methods:{

  },
}
</script>
<style scoped>

</style>
