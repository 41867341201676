<template>
  <div>
    <el-dialog
      title="选择商户"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div class="header">
        <el-input v-model="searchVal" placeholder="输入要查询的内容" />
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >
          查询
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="confirmClick"
        >
          确定
        </el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="create_time" label="创建日期" sortable>
        </el-table-column>
        <el-table-column prop="commercial_account" label="商户号">
        </el-table-column>
        <el-table-column prop="enterprise_name" label="商户名称">
        </el-table-column>
        <el-table-column prop="city" label="区域"> </el-table-column>
      </el-table>
      <div class="tableBottom">
        <span style=""
          >显示第&nbsp;{{
            tableData.length == 0 ? 0 : (page - 1) * length + 1
          }}&nbsp;至&nbsp;{{
            (page - 1) * length + tableData.length
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
        >

        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-button @click="homePage">首页</el-button>
          <el-button @click="upPage">上页</el-button>
          <el-pagination
            background
            layout=" pager,slot"
            :total="tableFinshNum"
            :current-page="page"
            @current-change="currentChange"
          ></el-pagination>
          <el-button @click="nextPage">下页</el-button>
          <el-button @click="endPage">末页</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialogVisible", "id"],
  data() {
    return {
      searchVal: "",
      tableData: [],
      length: 10,
      page: 1,
      tableFinshNum: 0,
      multipleSelection: [],
    };
  },
  mounted() {
    this.getTabList();
  },
  computed: {},
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    getTabList() {
      this.$http
        .post("/Client/commercial_owner/lst", {
          currentLength: this.length,
          currentPage: this.page,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    async searchClick() {
      if (!this.searchVal) return;
      try {
        const res = await this.$http.post("/Client/commercial_owner/search", {
          search: this.searchVal,
        });
        this.tableData = res.data.data;
        this.tableFinshNum = res.data.count;
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    confirmClick() {
      const commercial_id =
        this.multipleSelection[this.multipleSelection.length - 1].id;
      this.$http
        .post("/client/user/save", {
          commercial_id,
          member_id: this.$props.id,
        })
        .then((res) => {
          this.$message({
            type: res.data.status,
            message: res.data.msg,
          });
          if (res.data.status == "success") {
            this.$emit(
              "handleClose",
              this.multipleSelection[this.multipleSelection.length - 1]
                .enterprise_name
            );
          }
        });
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page <= 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page++;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabList();
      },
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .el-input {
    margin-right: 10px;
    width: 30%;
  }
}
</style>
