<template>
  <div>
    <el-dialog :title="'修改' + title" :visible.sync="changeShow" :before-close="changeClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="余额：">
          <el-input v-model="ruleForm.money"></el-input>
        </el-form-item>
        <el-form-item label="操作：">
          <el-radio v-model="ruleForm.opretion" label="1">加{{ title }}</el-radio>
          <el-radio v-model="ruleForm.opretion" label="0">减{{ title }}</el-radio>
        </el-form-item>
        <el-form-item label="修改原因：" prop="reason">
          <el-input v-model="ruleForm.reason" type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["title", "changeShow", "id"],
  data() {
    return {
      ruleForm: {
        money: "",
        opretion: "1",
        reason: "",
        member_id: "",
      },
      rules: {
        reason: [
          { required: true, message: "请填写修改原因", trigger: "change" },
        ],
      },
      card_balance: "",
    };
  },
  mounted() {
    this.ruleForm.member_id = this.$props.id;
    console.log(this.ruleForm.member_id);
  },
  computed: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$http.post(
            this.$props.title == "余额" ? "/client/balance_opretion/save" : "/client/balance_opretion/edit_species",
            this.ruleForm
          );
          if (res.data.status == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.card_balance = res.data.card_balance;
            this.$emit("changeClose", this.card_balance, this.$props.title != "余额");
          } else {
            this.$message.error(res.data.msg)
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeClose() {
      this.$emit("changeClose");
    },
  },
};
</script>
<style scoped></style>
