<template>
  <div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="consignee" label="收货人" width="70">
      </el-table-column>
      <el-table-column label="收货地址">
        <template slot-scope="scope">
          {{ scope.row.region }}{{ scope.row.location }}
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="联系电话" width="120">
      </el-table-column>
      <el-table-column prop="floor" label="楼层" width="80"> </el-table-column>
      <el-table-column label="默认" width="80">
        <template slot-scope="scope">
          {{ scope.row.default == 0 ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column label="叫水码" width="120">
        <template slot-scope="scope">
          <el-button @click="codeClick(scope.row)">叫水码</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
          <span style="margin: 0 10px"></span>
          <i class="el-icon-delete poiner" @click="handleDelete(scope.row)"></i>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="success" @click="dialogVisible = true">添加地址</el-button>
    <el-dialog
      title="收货地址"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <AddAddress
        v-if="dialogVisible"
        :id="id"
        @addressClose="handleClose"
        :message="message"
      />
    </el-dialog>
  </div>
</template>
<script>
import AddAddress from "./addAddress.vue";
export default {
  props: ["id"],
  components: { AddAddress },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      message: "",
    };
  },
  mounted() {
    this.getTabList();
  },
  computed: {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.getTabList();
      this.message = "";
    },
    getTabList() {
      this.$http
        .post("/client/shipping_address/lst", {
          member_id: this.$props.id,
        })
        .then((res) => {
          this.tableData = res.data;
          console.log(res);
        });
    },
    handleEdit(row) {
      this.message = row;
      this.dialogVisible = true;
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/client/shipping_address/del", {
              id: row.id,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style scoped></style>
