<template>
  <div style="padding: 20px 30px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-row>
        <el-col :span="24">
          <el-form-item label="会员卡号：">
            <span>{{ ruleForm.cardName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="绑定手机：" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="所在地：" required>
          <el-col :span="2">
            <el-select v-model="ruleForm.province">
              <el-option v-for="item in provinces" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-select v-model="ruleForm.city">
              <el-option v-for="item in cities" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-select v-model="ruleForm.town">
              <el-option v-for="item in townes" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="详细地址：" prop="address" style="width: 80%">
            <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="所属门店：" prop="ownedStore">
            <el-select v-model="ruleForm.ownedStore">
              <el-option v-for="item in ownedStores" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="推荐人：" prop="referrer">
            <span>{{ ruleForm.referrer }}</span>
            <el-button type="success" size="mini">
              <div style="display: flex; align-items: center; font-size: 14px">
                <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                <span style="margin-left: 5px">修改</span>
              </div>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="卡类型：" prop="cardType">
            <el-radio-group v-model="ruleForm.cardType">
              <el-radio v-for="item in cardTypes" :key="item" :label="item"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="卡等级：" prop="cardGrade">
            <el-radio-group v-model="ruleForm.cardGrade">
              <el-radio v-for="item in cardGrades" :key="item" :label="item"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="用户类型：" prop="userType">
            <el-radio-group v-model="ruleForm.userType">
              <el-radio v-for="item in userTypes" :key="item" :label="item"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注：" style="width: 80%">
            <el-input v-model="ruleForm.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="叫水周期：">
            <el-input v-model="ruleForm.callWaterPeriod"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="最小起订量：">
            <el-input v-model="ruleForm.minOrder"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="卡内余额：">
            <div style="display: flex; align-items: center">
              <span style="margin-right: 10px">{{ ruleForm.balance }}</span>
              <img style="width: 16px; height: 16px; margin-right: 5px" :src="require('@/assets/iconImg/logo.png')" />
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="卡内金币：">
            <div style="display: flex; align-items: center">
              <span style="margin-right: 10px">{{ ruleForm.carnetGold }}</span>
              <img style="width: 16px; height: 16px; margin-right: 5px" :src="require('@/assets/iconImg/logo.png')" />
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="当前欠款：">
            <span>{{ ruleForm.debt }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="当前欠票：">
            <span>{{ ruleForm.ticket }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      provinces: [],
      cities: [],
      townes: [],
      ownedStores: [],
      cardTypes: ['会员', '推广员', '代理商'],
      cardGrades: ['vip0', 'vip1', 'vip2', 'vip3', 'vip4', 'vip5', 'vip6'],
      userTypes: ['个人', '企业'],

      ruleForm: {
        cardName: "",
        name: "",
        phone: "",
        province: "",
        city: "",
        town: "",
        address: "",
        ownedStore: "",
        referrer: "",
        cardType: "",
        cardGrade: "",
        userType: "",
        remark: "",
        callWaterPeriod: "",
        minOrder: "",
        balance: "0",
        carnetGold: "0",
        debt: "0",
        ticket: "0",
      },
      rules: {
        name: [{ required: true, message: "必填字段", trigger: "blur" }],
        phone: [{ required: true, message: "必填字段", trigger: "blur" }],
        address: [{ required: true, message: "必填字段", trigger: "blur" }],
        ownedStore: [{ required: true, message: "必填字段", trigger: "blur" }],
        province: [{ required: true, message: "必填字段", trigger: "change" }],
        city: [{ required: true, message: "必填字段", trigger: "change" }],
        town: [{ required: true, message: "必填字段", trigger: "change" }],
        cardType: [{ required: true, message: "必填字段", trigger: "change" }],
        cardGrade: [{ required: true, message: "必填字段", trigger: "change" }],
        userType: [{ required: true, message: "必填字段", trigger: "change" }],
      },
    };
  },
  mounted() { },
  computed: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}
</style>
