<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>会员资料</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>

    <div style="padding: 20px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="first">
          <first-child></first-child>
        </el-tab-pane>
        <el-tab-pane label="收货地址" name="second">
          <second-child></second-child>
        </el-tab-pane>
        <el-tab-pane label="电子水票" name="third">
          <third-child></third-child>
        </el-tab-pane>
        <el-tab-pane label="空桶记录" name="fourth">
          <fourth-child></fourth-child>
        </el-tab-pane>
        <el-tab-pane label="会员卡包" name="fifth">
          <fifth-child></fifth-child>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import firstChild from "./details_child/firstChild.vue";
import secondChild from "./details_child/secondChild.vue";
import thirdChild from "./details_child/thirdChild.vue";
import fourthChild from "./details_child/fourthChild.vue";
import fifthChild from "./details_child/fifthChild.vue";
export default {
  components: {
    firstChild,
    secondChild,
    thirdChild,
    fourthChild,
    fifthChild,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  mounted() {},
  computed: {},
  methods: {
    closeClick() {
      this.$emit("close", 0);
    },
    submit() {},
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
