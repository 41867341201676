<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>会员资料</el-breadcrumb-item>
        <el-breadcrumb-item>会员编辑</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>
    <div class="box">
      <Header @headerChange="headerChange" :headers="headers" style="margin-bottom: 20px" />
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
        v-if="header == '基本信息'">
        <el-form-item label="会员卡号：">
          <div>{{ ruleForm.card_number }}</div>
        </el-form-item>
        <div class="row">
          <el-form-item label="姓名：" prop="member_name">
            <el-input v-model="ruleForm.member_name" />
          </el-form-item>
          <el-form-item label="绑定手机：" prop="bind_phone">
            <el-input v-model="ruleForm.bind_phone" />
          </el-form-item>
        </div>
        <el-form-item label="所在地：" required>
          <!-- <el-col :span="4">
            <el-form-item prop="province">
              <el-select
                v-model="province"
                placeholder="请选择"
                @focus="city1Focus"
                @change="city1Change"
              >
                <el-option
                  v-for="item in provinces"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="province">
            <el-form-item prop="city">
              <el-select
                v-model="city"
                placeholder="请选择"
                @focus="city2Focus"
                @change="city2Change"
              >
                <el-option
                  v-for="item in cities"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="city">
            <el-form-item prop="region">
              <el-select
                v-model="region"
                placeholder="请选择"
                @focus="city3Focus"
              >
                <el-option
                  v-for="item in regiones"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <span>{{ location.join("") }} </span>
          <el-cascader style="width:100px" placeholder="请选择地区" :options="options" v-model="location"
            @change="ruleForm.province = location[0]; ruleForm.city = location[1]; ruleForm.county = location[2]" />
        </el-form-item>
        <el-form-item label="详细地址：" prop="address">
          <el-input v-model="ruleForm.address" />
        </el-form-item>
        <div class="row">
          <el-form-item prop="shop_id" label="所属门店：">
            <el-select v-model="ruleForm.shop_id" placeholder="请选择">
              <el-option v-for="item in shop_ids" :key="item.id" :label="item.shop_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推荐人：">
            <span>{{ referrer }}</span>
            <el-button type="success" icon="el-icon-search" style="margin-left: 10px" size="mini"
              @click="handleAddUse">修改</el-button>
          </el-form-item>
        </div>
        <el-form-item label="卡类型：" prop="card_type">
          <el-radio-group v-model="ruleForm.card_type">
            <el-radio :label="0">会员</el-radio>
            <el-radio :label="1">推广员</el-radio>
            <el-radio :label="2">代理商</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="row">
          <el-form-item label="卡等级：" prop="level">
            <el-radio-group v-model="ruleForm.level">
              <el-radio :label="item.value" v-for="item in levels" :key="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="会员到期时间：">
            <el-date-picker v-model="ruleForm.expiration_time" type="datetime" placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item label="用户类型：" prop="user_type">
          <el-radio-group v-model="ruleForm.user_type">
            <el-radio :label="0">个人</el-radio>
            <el-radio :label="1">企业</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="row">
          <el-form-item label="支付方式：" prop="pay_method">
            <el-radio-group v-model="ruleForm.pay_method">
              <el-radio :label="0">现结</el-radio>
              <el-radio :label="1">记账</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="记账金额：" v-if="ruleForm.pay_method == 1">
            <el-input v-model="ruleForm.account_charge" style="width: 30%" />
            <el-button type="primary" size="mini" style="margin-left: 10px" @click="addProductClick">记账商品</el-button>
          </el-form-item>
        </div>
        <el-form-item label="备注：">
          <el-input v-model="ruleForm.remake" />
        </el-form-item>
        <div class="row">
          <el-form-item label="叫水周期：">
            <el-input v-model="ruleForm.water_cycle" />
          </el-form-item>
          <el-form-item label="最小起订量：">
            <el-input v-model="ruleForm.order_quantit" />
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="卡内余额：">
            <span>{{ ruleForm.card_balance }}</span><i class="el-icon-edit" style="margin: 0 10px 0 20px"
              @click="balanceChange('余额')" /><i class="el-icon-search" @click="detailClick('余额')" />
          </el-form-item>
          <el-form-item label="卡内金币：">
            <span>{{ ruleForm.card_gold }}</span><i class="el-icon-edit" @click="balanceChange('金币')"
              style="margin: 0 10px 0 20px" /><i class="el-icon-search" @click="detailClick('金币')" />
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="当前欠款：">
            <span>{{ ruleForm.current_debt }}</span>
          </el-form-item>
          <el-form-item label="当前欠票：">
            <span>{{ ruleForm.current_bill }}</span>
          </el-form-item>
        </div>
        <el-form-item label="指定派单：">
          <el-select v-model="ruleForm.assgin_shop" placeholder="请选择">
            <el-option v-for="item in shop_ids" :key="item.id" :label="item.shop_name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="ruleForm.assgin_deliver" placeholder="请选择" style="margin-left: 10px">
            <el-option v-for="item in assgin_delivers" :key="item.id" :label="item.shop_name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
        </el-form-item>
      </el-form>
      <Address :id="userId" v-else-if="header == '收货地址'" />
    </div>
    <AddUse :addUser="addUser" @handleConfirm="handleConfirm" @beforeClose="beforeClose" v-if="addUser" />
    <AddProduct :addProduct="addProduct" @productClose="productClose" :tableDatas="productTab" v-if="addProduct" />
    <EditChange :title="editTitle" :change-show="editShow" @changeClose="changeClose" :id="userId" v-if="editShow" />
    <EditDetail :detailShow="detailShow" @detailClose="detailClose" :title="editTitle" :id="userId" v-if="detailShow" />
    <EditDetail1 :detailShow="detailShow1" @detailClose="detailClose1" :title="editTitle" :id="userId"
      v-if="detailShow1" />
  </div>
</template>
<script>
import Header from "./header.vue";
import AddUse from "./addUse.vue";
import AddProduct from "./addProduct.vue";
import EditChange from "./editChange.vue";
import EditDetail from "./editDetail.vue";
import EditDetail1 from "./jb_editDetail.vue";
import Address from "./address.vue";
import { getDate } from "../../../../../until/utilse";
import { pcaTextArr } from "element-china-area-data";
export default {
  props: ["data"],
  components: { Header, AddProduct, AddUse, EditChange, EditDetail, Address, EditDetail1 },
  data() {
    return {
      options: pcaTextArr,
      location: [],
      header: "基本信息",
      headers: ["基本信息", "收货地址", "电子水票", "空桶记录", "会员卡包"],
      ruleForm: {
        card_number: "",
        member_name: "",
        bind_phone: "",
        address: "",
        shop_id: "",
        commercial_id: "",
        referrer: {},
        card_type: "",
        expiration_time: "",
        level: "",
        user_type: "",
        pay_method: "",
        remake: "",
        card_gold: "",
        card_balance: "",
        current_debt: "",
        current_bill: "",
        account_charge: "",
        assgin_deliver: "",
        assgin_shop: "",
        charge_product: "",
      },
      rules: {
        member_name: [{ required: true, message: "必填", trigger: "blur" }],
        bind_phone: [{ required: true, message: "必填", trigger: "blur" }],
        address: [{ required: true, message: "必填", trigger: "blur" }],
        shop_id: [{ required: true, message: "必填", trigger: "blur" }],
        card_type: [{ required: true, message: "必填", trigger: "blur" }],
        level: [{ required: true, message: "必填", trigger: "blur" }],
        user_type: [{ required: true, message: "必填", trigger: "blur" }],
        pay_method: [{ required: true, message: "必填", trigger: "blur" }],
      },
      levels: [
        { label: "vip0", value: "0" },
        { label: "vip1", value: "1" },
        { label: "vip2", value: "2" },
        { label: "vip3", value: "3" },
        { label: "vip4", value: "4" },
        { label: "vip5", value: "5" },
      ],
      assgin_delivers: [],
      province: "",
      city: "",
      county: "",
      provinces: [],
      cities: [],
      regiones: [],
      shop_ids: [],
      myKey: "WGOBZ-JTR35-ACLIY-IGOU6-X2L63-XGFP3",
      addUser: false,
      addProduct: false,
      editTitle: "",
      editShow: false,
      userId: "",
      detailShow: false,
      detailShow1: false,
      productTab: [],
      referrer: "",
    };
  },
  mounted() {
    if (this.$props.data) {
      console.log(this.$props.data);
      const data = this.$props.data;
      this.ruleForm = this.$props.data;
      this.province = data.province;
      this.city = data.city;
      this.county = data.county;
      if (data.referrer?.member_name) {
        this.referrer = data.referrer.member_name;
      }
    }
    this.ruleForm.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.$http
      .post("/system/shop/lst", {
        commercial_id: this.ruleForm.commercial_id,
      })
      .then((res) => {
        this.shop_ids = res.data.data;
      });
    this.userId = this.ruleForm.member_id;
  },
  computed: {},
  methods: {
    headerChange(text) {
      this.header = text;
    },
    addProductClick() {
      this.addProduct = true;
      this.productTab = this.ruleForm.charge_product;
    },
    closeClick() {
      this.$emit("close");
    },
    handleAddUse() {
      this.addUser = true;
    },
    beforeClose() {
      this.addUser = false;
    },
    balanceChange(title) {
      this.editTitle = title;
      this.editShow = true;
    },
    //记账商品回调
    productClose(data) {
      this.addProduct = false;
      this.ruleForm.charge_product = data;
      console.log(this.ruleForm.charge_product);
    },
    handleConfirm(data) {
      this.addUser = false;
      // console.log(this.ruleForm.referrer)
      this.ruleForm.referrer = {}
      this.ruleForm.referrer.member_name = data.name;
      this.ruleForm.referrer.member_id = data.id;
      this.referrer = data.name;
    },
    detailClick(title) {
      this.editTitle = title;
      if (title == "金币") {
        this.detailShow1 = true;
        return;
      }
      this.detailShow = true;
    },
    detailClose() {
      this.detailShow = false;
    },
    detailClose1() {
      this.detailShow1 = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.referrer = this.ruleForm.referrer?.member_id;
          this.ruleForm.shop_id = this.ruleForm.shop_id.id;
          for (const item in this.ruleForm) {
            if (this.ruleForm[item] === null) {
              delete this.ruleForm[item];
            }
          }
          if (Array.isArray(this.ruleForm.charge_product)) {
            this.ruleForm.charge_product = this.ruleForm.charge_product.map(
              (item) => {
                return item.id;
              }
            );
          } else {
            this.ruleForm.charge_product = [];
          }
          this.ruleForm.expiration_time = getDate(
            this.ruleForm.expiration_time
          );
          if (this.ruleForm.assgin_shop?.constructor == Object) {
            this.ruleForm.assgin_shop = this.ruleForm.assgin_shop.id;
          }
          if (!this.ruleForm.assgin_deliver) {
            this.ruleForm.assgin_deliver = "";
          }
          this.$http.post("/client/member/save", this.ruleForm).then((res) => {
            this.$message({
              type: res.data.status,
              message: res.data.msg,
            });
            if (res.data.status == "success") {
              this.closeClick();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeClose(data, type) {
      console.log(data, type);
      if (data) {
        if (type) {
          this.ruleForm.card_gold = data
        } else {
          this.ruleForm.card_balance = data;
        }
      }
      this.editShow = false;
    },
    city1Focus() {
      if (this.provinces.length == 0) {
        this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
          key: this.myKey,
          output: "jsonp",
        }).then((res) => {
          res.result[0].forEach((item) => {
            this.provinces.push({ name: item.fullname, id: item.id });
          });
        });
      }
    },

    city2Focus() {
      if (
        this.cities.length == 0 ||
        this.province != sessionStorage.getItem("province")
      ) {
        sessionStorage.setItem("province", this.province);
        this.cities = [];
        this.provinces.forEach((item) => {
          if (item.name == this.province) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              res.result[0].forEach((item) => {
                this.cities.push({
                  name: item.fullname,
                  id: item.id,
                });
              });
            });
          }
        });
      }
    },
    city3Focus() {
      if (
        this.regiones.length == 0 ||
        this.cities != sessionStorage.getItem("cities")
      ) {
        sessionStorage.setItem("cities", this.city);
        this.regiones = [];
        this.cities.forEach((item) => {
          if (item.name == this.city) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              console.log(res);
              res.result[0].forEach((item) => {
                this.regiones.push(item.fullname);
              });
            });
          }
        });
      }
    },
    city1Change() {
      this.city = "";
      this.county = "";
    },
    city2Change() {
      this.county = "";
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box {
  margin: 20px 30px 0;

  .demo-ruleForm {
    margin: 20px 80px 0;

    .row {
      display: flex;
      justify-content: space-between;
      padding-right: 120px;
    }
  }

  .el-form-item /deep/ .el-form-item__label {
    white-space: nowrap;
  }

  .el-form-item {
    i {
      cursor: pointer;
    }
  }
}
</style>
