<template>
  <div>
    <div class="header">
      <div
        class="header-item"
        :class="item == header ? 'active' : ''"
        v-for="item in headers"
        :key="item"
        @click="headerChange(item)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["headers"],
  data() {
    return {
      header: "",
    };
  },
  mounted() {
    this.header = this.$props.headers[0];
  },
  computed: {},
  methods: {
    headerChange(item) {
      this.header = item;
      this.$emit("headerChange", item);
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  border-bottom: 1px solid #222222;
  display: flex;
  &-item {
    padding: 4px 10px;
    background-color: #e8e8e8;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    font-weight: bolder;
  }
  .active {
    background-color: #222222;
    color: #ffffff;
  }
}
</style>
