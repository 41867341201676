<template>
    <div>
        <div v-if="isShow == 0">
            <div class="breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
                    <el-breadcrumb-item>用户资料</el-breadcrumb-item>
                </el-breadcrumb>
                <el-button type="success" style="height: 36px" @click="reload">
                    <i style="font-size: 18px" class="el-icon-refresh-right"></i>
                </el-button>
            </div>

            <el-row style="padding: 20px 20px 0; white-space: nowrap">
                <el-col :span="6">
                    <span>日期范围：</span>
                    <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-col>
                <el-col :span="2">
                    <el-select v-model="option2" clearable filterable placeholder="发票类型">
                        <el-option v-for="item in option2es" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <el-select v-model="option1" clearable filterable placeholder="用户状态">
                        <el-option v-for="item in option1es" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="8">
                    <el-input v-model="QueryContent" placeholder="手机号、姓名、昵称"
                        style="width: 70%; margin-right: 5px"></el-input>
                    <el-button type="success" size="mini" style="margin-left: 5px" @click="getTabList">
                        <div style="display: flex; align-items: center; font-size: 14px">
                            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                            <span style="margin-left: 3px">查询</span>
                        </div>
                    </el-button>
                    <!-- <el-button type="primary" size="mini" style="margin-left: 5px" @click="exportClick">
                        <div style="display: flex; align-items: center; font-size: 14px">
                            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                            <span style="margin-left: 3px">导出</span>
                        </div>
                    </el-button> -->
                </el-col>
            </el-row>
            <div style="padding: 20px">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%">
                    <el-table-column prop="create_time" sortable label="时间"></el-table-column>
                    <el-table-column prop="enterprise_name" label="商家"></el-table-column>
                    <el-table-column prop="member_name" label="会员"></el-table-column>
                    <el-table-column label="类型">
                        <template slot-scope="scope">
                            <span>
                                {{ option2es.find(el => {
                                    return scope.row.type == el.value;
                                }).label }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="corporate_name" sortable label="公司名称"></el-table-column>
                    <el-table-column prop="identification" sortable label="纳税识别号"></el-table-column>
                    <el-table-column prop="money" sortable label="金额"></el-table-column>
                    <el-table-column prop="address" sortable label="公司地址"></el-table-column>
                    <el-table-column prop="phone" sortable label="公司电话"></el-table-column>
                    <el-table-column prop="bank" sortable label="开户行"></el-table-column>
                    <el-table-column prop="account" sortable label="账户"></el-table-column>
                    <el-table-column prop="notes" sortable label="备注"></el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <span>
                                {{ option1es.find(el => {
                                    return scope.row.status == el.value;
                                }).label }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="handleAssigned(scope.row)"
                                style="margin:10px 0px">确认开票</el-button>

                            <el-button @click="handleDetails(scope.row)">取消</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="tableBottom">
                    <span style="">显示第&nbsp;{{
                        tableData.length == 0 ? 0 : (page - 1) * length + 1
                    }}&nbsp;至&nbsp;{{
    (page - 1) * length + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>

                    <div style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
                        <el-button @click="homePage">首页</el-button>
                        <el-button @click="upPage">上页</el-button>
                        <el-pagination background layout=" pager,slot" :total="tableFinshNum" :current-page="page"
                            @current-change="currentChange"></el-pagination>
                        <el-button @click="nextPage">下页</el-button>
                        <el-button @click="endPage">末页</el-button>
                    </div>
                </div>
            </div>
        </div>

        <assigned v-if="isShow == 1" @close="onClose" :assigned_message="assignedMsg"></assigned>
        <UpDateUser v-if="isShow == 2" @close="onClose" :data="assignedMsg" />
    </div>
</template>
<script>
import assigned from "./childrens/assigned.vue";
import detail from "./childrens/details.vue";
import { getTime } from "../../../../until/getTime";
import UpDateUser from "../label3_memberInformation/childrens/memberEdit.vue";
import { xlixs } from "@/until/xlsx";
export default {
    components: {
        assigned,
        detail,
        UpDateUser,
    },
    data() {
        return {
            date: "",

            option1: "",
            option1es: [
                { label: "未确认", value: 1 },
                { label: "已开票", value: 2 },
                { label: "已取消", value: 3 },
            ],
            option2: "",
            option2es: [
                { label: "普通发票", value: 1 },
                { label: "增值税专用发票", value: 2 },
            ],
            QueryContent: "",

            tableData: [],
            tableFinshNum: 0,
            isShow: 0,
            assignedMsg: {},
            page: 1,
            length: 10,
        };
    },
    mounted() {
        this.getTabList();
    },
    computed: {},
    methods: {
        reload() {
            location.reload();
        },
        onClose() {
            this.isShow = 0;
            this.getTabList();
            this.assignedMsg = "";
        },
        getTabList() {
            // this.$http
            //     .post("/client/user/lst", {
            //         currentPage: this.page,
            //         currentLength: this.length,
            //     })
            //     .then((res) => {
            //         this.tableData = res.data.data;
            //         this.tableFinshNum = res.data.count;
            //     });
            this.$http.post("/client/invoice/lst", {
                currentPage: this.page,
                currentLength: this.length,
                firstTime: this.date[0] && getTime(this.date[0]),
                lastTime: this.date[1] && getTime(this.date[1]),
                search: this.QueryContent,
                status: this.option1,
                type: this.option2,
            }).then((res) => {
                this.tableData = res.data.data;
                this.tableFinshNum = res.data.count;
            });
        },
        handleAssigned({ id }) {
            this.$confirm("操作确认", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$http.post("/client/invoice/edit", {
                    id, status: 1
                }).then(res => {
                    if (res.data.status == "success") {
                        this.$message.success("操作成功")
                    } else {
                        this.$message("操作失败")
                    }
                })
            })
        },
        handleDetails({ id }) {
            this.$confirm("操作确认", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$http.post("/client/invoice/edit", {
                    id, status: 2
                }).then(res => {
                    if (res.data.status == "success") {
                        this.$message.success("操作成功")
                    } else {
                        this.$message("操作失败")
                    }
                })
            })
        },
        serachClick() {
            // this.$http
            //     .post("/client/user/search", {
            //         firstTime: this.date[0] && getTime(this.date[0]),
            //         lastTime: this.date[1] && getTime(this.date[1]),
            //         search: this.QueryContent,
            //         status: this.option1,
            //         currentPage: this.page,
            //         currentLength: this.length,
            //     })
            //     .then((res) => {
            //         this.tableData = res.data.data;
            //         this.tableFinshNum = res.data.count;
            //     });
            this.getTabList()
        },
        currentChange(page) {
            this.page = page;
        },
        homePage() {
            this.page = 1;
        },
        upPage() {
            if (this.page <= 1) return;
            this.page -= 1;
        },
        nextPage() {
            if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
            this.page++;
        },
        endPage() {
            this.page = Math.ceil(this.tableFinshNum / this.length);
        },
        exportClick() {
            this.$confirm("每次导出最大商品数为10000条？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    const res = await this.$http.post("/client/user/export");
                    let arr = res.data.data;
                    arr = arr.map((item) => {
                        return Object.values(item);
                    });
                    xlixs(
                        arr,
                        ["姓名", "电话", "注册日期", "最后购买时间", "地址(默认地址)"],
                        "data"
                    );
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
    },
    watch: {
        page: {
            handler() {
                this.getTabList();
            },
        },
    },
};
</script>
<style scoped lang="less">
.breadcrumb {
    height: 40px;
    line-height: 40px;
    box-sizing: content-box;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 20px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tableBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666;
    font-size: 14px;
}

/deep/ .el-col {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size: 14px;
    margin: 0 5px;
}

// /deep/ .cell {
//     padding: 0 !important;
//     white-space: nowrap !important;
// }
</style>
  