<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>用户资料</el-breadcrumb-item>
        <el-breadcrumb-item>管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>

    <div style="padding: 20px">
      <el-tabs v-model="activeName" @tab-click="handleTabs">
        <el-tab-pane label="基本信息" name="first">
          <div style="padding: 30px">
            <el-form ref="form" :model="form" label-width="150px">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="头像：">
                    <div class="block" style="margin-left: 20px">
                      <el-avatar :size="80" :src="circleUrl"></el-avatar>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                  <el-form-item label="昵称：">
                    <span>{{ form.nick_name }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="姓名：">
                    <span>{{ form.member_name }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="绑定手机：">
                    <span>{{ form.bind_phone }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                  <el-form-item label="最后登录日期：">
                    <span>{{ form.login_end_time }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="最后购买日期：">
                    <span>{{ form.purchase_date }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="归属商户：">
                    <span>{{ form.commercial_id }}</span>
                    <div>
                      <el-button
                        type="success"
                        size="mini"
                        icon="el-icon-search"
                        @click="subMint"
                      >
                        过户
                      </el-button>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="账单明细" name="second">
          <div style="padding: 20px">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="date"
                sortable
                label="日期"
              ></el-table-column>
              <el-table-column prop="abstract" label="摘要"></el-table-column>
              <el-table-column
                prop="income"
                sortable
                label="收入"
              ></el-table-column>
              <el-table-column
                prop="expend"
                sortable
                label="支出"
              ></el-table-column>
              <el-table-column
                prop="surplus"
                sortable
                label="结余"
              ></el-table-column>
            </el-table>
            <div class="tableBottom">
              <span style=""
                >显示第&nbsp;1&nbsp;至&nbsp;{{
                  tableFinshNum
                }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
              >

              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <el-button>首页</el-button>
                <el-button>上页</el-button>
                <el-pagination
                  background
                  layout=" pager,slot"
                  :total="1"
                ></el-pagination>
                <el-button>下页</el-button>
                <el-button>末页</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <AssignedChild
      :dialogVisible="assignedShow"
      @handleClose="handleClose"
      :id="id"
    />
  </div>
</template>

<script>
import AssignedChild from "./assigned_child/assifned_child.vue";
export default {
  props: ["assigned_message"],
  components: { AssignedChild },
  data() {
    return {
      activeName: "first",

      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      form: {},
      tableData: [],

      tableFinshNum: 0,
      assignedShow: false,
      id: "",
    };
  },
  mounted() {
    this.form = this.$props.assigned_message;
    this.id = this.$props.assigned_message.member_id;
    // this.shopName = this.$props.assigned_message.shop_id.shop_name;
  },
  computed: {},
  methods: {
    closeClick() {
      this.$emit("close", 0);
    },
    handleTabs(tab, event) {
      console.log(tab, event);
    },
    subMint() {
      this.assignedShow = true;
    },
    handleClose(shopName) {
      this.assignedShow = false;
      if (shopName) {
        this.form.commercial_id = shopName;
      }
    },
    handleEdit() {},
    handleDelete() {},
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin: 0 5px;
}
/deep/ .el-form-item__label {
  font-size: 16px !important;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}
</style>
