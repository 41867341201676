<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      v-show="!locationShow"
    >
      <el-form-item label="收货人：" prop="consignee">
        <el-input v-model="ruleForm.consignee"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="地区：" prop="region">
        <span>{{ ruleForm.region }}</span>
        <el-button type="primary" @click="locationShow = true">选择</el-button>
      </el-form-item>
      <el-form-item label="地址：" prop="location">
        <el-input v-model="ruleForm.location"></el-input>
      </el-form-item>
      <div class="row">
        <el-form-item label="楼层：" prop="floor">
          <el-select v-model="ruleForm.floor" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="楼层费/单件:" prop="floor_chage" v-if="message">
          <el-input v-model="ruleForm.floor_chage"></el-input>
        </el-form-item>
        <el-form-item label="是否默认：" prop="default">
          <el-checkbox v-model="ruleForm.default"></el-checkbox>
        </el-form-item>
      </div>
      <el-form-item label="配送站点：" prop="shop_id">
        <el-select v-model="ruleForm.shop_id" placeholder="请选择">
          <el-option
            v-for="item in shops"
            :key="item.id"
            :label="item.shop_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
    <Address
      :is-pop="false"
      :dialog-visible="locationShow"
      @dialogVisible="addressClose"
      v-show="locationShow"
      @rowClick="rowClick"
    />
  </div>
</template>
<script>
import Address from "../../../../addAddress/location-add.vue";
export default {
  components: { Address },
  props: ["id", "message"],
  data() {
    return {
      ruleForm: {
        consignee: "",
        phone: "",
        region: "",
        location: "",
        floor: 0,
        floor_chage: "",
        default: false,
        shop_id: "",
        member_id: "",
      },
      rules: {
        consignee: [
          { required: true, message: "请填写收货人", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请填写联系电话", trigger: "blur" }],
        region: [{ required: true, message: "请选择地区", trigger: "blur" }],
        location: [{ required: true, message: "请填地址", trigger: "blur" }],
      },
      locationShow: false,
      options: [
        { label: "有电梯", value: 0 },
        { label: "1楼", value: 1 },
        { label: "2楼", value: 2 },
        { label: "3楼", value: 3 },
        { label: "4楼", value: 4 },
        { label: "5楼", value: 5 },
        { label: "6楼", value: 6 },
        { label: "7楼", value: 7 },
        { label: "8楼", value: 8 },
        { label: "9楼", value: 9 },
      ],
      shops: [],
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.$http
      .post("/system/shop/lst", {
        commercial_id: this.commercial_id,
      })
      .then((res) => {
        this.shops = res.data.data;
      });
    if (this.$props.message) {
      this.ruleForm = this.$props.message;
      console.log(this.$props.message);
    }
    this.ruleForm.member_id = this.$props.id;
    this.ruleForm.default = this.$props.default == 0 ? false : true;
  },
  computed: {},
  methods: {
    addressClose() {
      this.locationShow = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.$props.message) {
            delete this.ruleForm.floor_chage;
          }
          this.ruleForm.default = this.ruleForm.default ? 1 : 0;
          this.$http
            .post("/client/shipping_address/save", this.ruleForm)
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.$emit("addressClose");
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    rowClick(address, location) {
      //   console.log(address, location);
      this.ruleForm.region = address;
      this.ruleForm.location = location;
    },
  },
};
</script>
<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-form-item /deep/ .el-form-item__label {
    white-space: normal;
  }
}
</style>
